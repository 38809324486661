(function($) {
  $(document).ready(function() {
    var $menu = $('#menu'),
      $menulink = $('.menu-link');
    $menulink.click(function() {
      $menulink.toggleClass('is-active');
      $menu.toggleClass('is-active');
      return false;
    });
  });
  
  $('.accordion__content').hide();
  $('.accordion__trigger').click(function() {
    if ($(this).hasClass('is-selected')) {
      $(this).removeClass('is-selected');
      $(this)
        .parent()
        .next()
        .slideUp();
    } else {
      $('.accordion__trigger').removeClass('is-selected');
      $(this).addClass('is-selected');
      $('.accordion__content').slideUp();
      $(this)
        .parent()
        .next()
        .slideDown();
    }
    return false;
  });
})(jQuery);
